import * as React from "react";
import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { ListItemText, Typography } from "@mui/material";
import styled from "@emotion/styled";
import { PropTypes } from "prop-types";
import IconMapper from "components/IconMapper";
import { Box } from "@mui/system";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    whiteSpace: "normal",
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  backgroundColor: theme.palette.main.lightAsh2,
  borderRadius: 5,
  // style root
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderWidth: "0px",
    },
    "&:hover fieldset": {
      borderWidth: "0px",
    },
    "&.Mui-focused fieldset": {
      borderWidth: "0px",
    },
  },
}));

export default function FilterMenu({
  items,
  name,
  selectedList,
  setSelectedList,
  onInputChange,
  isMultiple,
  defaultReplaceValue,
}) {
  const theme = useTheme();
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    onInputChange(event, value);
    setSelectedList(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  return (
    <div>
      <StyledFormControl fullWidth>
        <Select
          multiple={isMultiple ? true : false}
          name={name}
          id={name}
          label={name}
          fullWidth
          displayEmpty
          value={selectedList}
          onChange={handleChange}
          input={<OutlinedInput />}
          renderValue={(selected) => {
            if (selected.length === 0) {
              return (
                <Box
                  display={"flex"}
                  sx={{
                    marginLeft: "5px",
                    color: theme.palette.main.darkAsh,
                  }}
                >
                  <IconMapper
                    name="university"
                    color={theme.palette.main.darkAsh}
                  />
                  <Typography
                    sx={{
                      color: theme.palette.main.darkAsh,
                      ml: 2.5,
                    }}
                  >
                    {name}
                  </Typography>
                </Box>
              );
            }

            return (
              <Box
                display={"flex"}
                sx={{
                  marginLeft: "5px",
                  color: theme.palette.main.darkAsh,
                }}
              >
                <IconMapper
                  name="university"
                  color={theme.palette.main.darkAsh}
                />
                <Typography
                  sx={{
                    color: theme.palette.main.darkAsh,
                    ml: 2.5,
                  }}
                >
                  {defaultReplaceValue ? defaultReplaceValue : selectedList[0]}
                </Typography>
              </Box>
            );
          }}
          MenuProps={MenuProps}
          inputProps={{ "aria-label": "Without label" }}
        >
          {items.map((name) => (
            <MenuItem
              key={name}
              value={name}
              style={getStyles(name, selectedList, theme)}
            >
              <ListItemText
                primary={name}
                sx={{ color: theme.palette.main.darkAsh }}
              />
            </MenuItem>
          ))}
        </Select>
      </StyledFormControl>
    </div>
  );
}

FilterMenu.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
  placeholder: PropTypes.string,
  name: PropTypes.string.isRequired,
  selectedList: PropTypes.arrayOf(PropTypes.string).isRequired,
  setSelectedList: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired,
  isMultiple: PropTypes.bool,
  defaultReplaceValue: PropTypes.string,
};
