const parseError = (error) => {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    throw error.response.data.message;
  } else if (error.request) {
    // The request was made but no response was received
    throw error.request;
  } else {
    // Something happened in setting up the request that triggered an Error
    throw error.message;
  }
};

const parseResponse = (res) => {
  return {
    status: res.status,
    message: res.data.message,
  };
};

const parseResponseSignin = (res) => {
  // convert res.data.config.expires to Date object
  const { message, token, config } = res.data;
  if (config.expires != 0) {
    const expires = new Date(config.expires);
    config.expires = expires;
  }

  return {
    status: res.status,
    message,
    token,
    config,
  };
};

export { parseError, parseResponse, parseResponseSignin };
