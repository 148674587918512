import * as axios from "axios";
import { SERVER_ENDPOINT } from "config/env";

/**
 * @description Serves as the base API endpoint for the application. It provides the authorization token in every request
 */
export default class Api {
  constructor() {
    this.apiToken = null;
    this.client = null;
    this.apiEndpoint = SERVER_ENDPOINT;
  }

  // path can be: /api/v1
  init(path) {
    let headers = {
      Accept: "application/json",
    };

    //https://github.com/axios/axios#creating-an-instance
    this.client = axios.create({
      baseURL: `${this.apiEndpoint}${path}`,
      timeout: 5000,
      headers: headers,
      withCredentials: true,
    });

    return this.client;
  }
}

