import { useRoutes, Navigate } from "react-router-dom";
import NotFound from "components/404";
import {
  NOT_FOUND,
  UNAUTHORIZED,
  SIGN_IN,
  SIGN_UP,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  VERIFICATION,
} from "constants/route";
import Unauthorized from "components/401";
import SignInPage from "pages/SignInPage";
import SignUpPage from "pages/SignUpPage";
import ForgotPasswordPage from "pages/ForgotPasswordPage";
import ResetPasswordPage from "pages/ResetPasswordPage";
import Layout from "pages/Layout";
import { NotRequireAuth } from "config/auth";
import VerificationPage from "pages/VerificationPage";

/**
 *
 * Pages that require a navbar and a footer are wrapped in the Layout component.
 */
function Router() {
  return useRoutes([
    {
      path: "/",
      element: (
        <NotRequireAuth>
          <Layout />
        </NotRequireAuth>
      ),
      children: [
        { path: "/", element: <Navigate to={SIGN_IN} replace /> },
        {
          path: SIGN_IN,
          element: <SignInPage />,
        },
        {
          path: SIGN_UP,
          element: <SignUpPage />,
        },
        {
          path: FORGOT_PASSWORD,
          element: <ForgotPasswordPage />,
        },
        {
          path: RESET_PASSWORD,
          element: <ResetPasswordPage />,
        },
        {
          path: VERIFICATION,
          element: <VerificationPage />,
        },
      ],
    },

    { path: UNAUTHORIZED, element: <Unauthorized /> },

    { path: NOT_FOUND, element: <NotFound /> },
    { path: "/", element: <Navigate to={SIGN_IN} replace /> },
    { path: "*", element: <Navigate to={NOT_FOUND} replace /> },
  ]);
}
export default Router;
