import React, { useEffect, useState } from "react";
// import PropTypes from 'prop-types'

// mui
import { Box, Button, Paper, Typography, useTheme } from "@mui/material";
import TextInputLayout from "components/TextInputLayout";
import Adornment from "components/TextInputLayout/Adornment";
import Auth from "api/auth";
import FilterMenu from "components/FilterMenu";
import Constants from "api/constants";
import notify from "util/index";
import { Helmet } from "react-helmet-async";
import LinearLoadingBar from "components/LinearLoadingBar";

const useClasses = (theme) => ({
  paper: {
    maxWidth: 430,
    px: theme.spacing(7),
    pb: theme.spacing(5),
    mx: "auto",
    mt: theme.spacing(6),
    boxShadow: "0px 4px 13px rgba(61, 61, 61, 0.1)",
  },
  button: {
    p: theme.spacing(2),
  },
  itemSpacing: {
    py: theme.spacing(1),
  },
  itemSpacingMedium: {
    py: theme.spacing(2),
  },
  itemSpacingLarge: {
    py: theme.spacing(3),
  },
});

const SignUpPage = () => {
  const [filterList, setFilterList] = useState([]);
  const [filterItems, setFilterItems] = useState([]);
  const theme = useTheme();
  const classes = useClasses(theme);
  const [form, setForm] = React.useState(null);
  const [showPassword, setShowPassword] = React.useState({
    pass: false,
    comp: false,
  });
  const [processing, setProcessing] = React.useState(false);

  useEffect(() => {
    const api = new Constants();
    api.getConstants("universities").then((res) => {
      const { universities } = res.message;

      // sort alphabetically
      const sorted = universities.sort((a, b) => {
        if (a < b) {
          return -1;
        }
        return 1;
      });

      setFilterItems(sorted);
    });
  }, []);

  const handleFilterChange = (event, value) => {
    setForm((prevState) => ({
      ...prevState,
      ["institution"]: value,
    }));
  };

  const onInput = (event) => {
    const { value, name } = event.target;
    setForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const onSubmitClick = () => {
    console.log(form);
    setProcessing(true);
    const auth = new Auth();
    auth
      .createUserWithEmail(form)
      .then((res) => {
        console.log(res);
        notify(res.message, "success");
        setProcessing(false);
      })
      .catch((error) => {
        console.log(error);
        notify(error, "error");
        setProcessing(false);
      });
  };

  const handleClickShowPassword = () => {
    setShowPassword({ ...showPassword, pass: !showPassword.pass });
  };

  const handleClickShowCompPassword = () => {
    setShowPassword({ ...showPassword, comp: !showPassword.comp });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div>
      <LinearLoadingBar loading={processing} />
      <Paper sx={classes.paper}>
        <Helmet>
          <title>Sign Up</title>
        </Helmet>
        <Box sx={classes.itemSpacingLarge}>
          <Typography sx={{ textAlign: "center" }} variant="h2">
            Sign Up
          </Typography>
        </Box>
        <Box sx={classes.itemSpacing}>
          <TextInputLayout
            icon="label"
            placeholder="Handle Name"
            type="text"
            name="handle"
            onInputChange={onInput}
          />
        </Box>
        <Box sx={classes.itemSpacing}>
          <TextInputLayout
            icon="mail"
            placeholder="Email"
            type="email"
            name="email"
            onInputChange={onInput}
          />
        </Box>
        <Box sx={classes.itemSpacing}>
          <FilterMenu
            name="Select University"
            items={filterItems && filterItems}
            selectedList={filterList}
            setSelectedList={setFilterList}
            onInputChange={handleFilterChange}
          />
        </Box>
        <Box sx={classes.itemSpacing}>
          <TextInputLayout
            icon="lock"
            placeholder="Password"
            type={showPassword.pass ? "text" : "password"}
            name="password"
            endAdornment={
              <Adornment
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                visible={showPassword.pass}
              />
            }
            onInputChange={onInput}
          />
        </Box>
        <Box sx={classes.itemSpacing}>
          <TextInputLayout
            icon="lock"
            placeholder="Confirm Password"
            type={showPassword.comp ? "text" : "password"}
            endAdornment={
              <Adornment
                onClick={handleClickShowCompPassword}
                onMouseDown={handleMouseDownPassword}
                visible={showPassword.comp}
              />
            }
            name="confirmPassword"
            onInputChange={onInput}
          />
        </Box>

        <Box sx={classes.itemSpacingMedium}>
          <Button
            sx={classes.button}
            variant="contained"
            color="secondary"
            fullWidth
            onClick={onSubmitClick}
            disabled={processing}
          >
            Submit
          </Button>
        </Box>
      </Paper>
    </div>
  );
};

SignUpPage.propTypes = {};

export default SignUpPage;
