import Auth from "api/auth";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Button, Paper, Typography, useTheme } from "@mui/material";
import { SIGN_IN } from "constants/route";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import LinearLoadingBar from "components/LinearLoadingBar";

const useClasses = (theme) => ({
  paper: {
    maxWidth: 600,
    px: theme.spacing(8),
    pb: theme.spacing(2),
    mx: "auto",
    mt: theme.spacing(6),
    boxShadow: theme.customShadows.primary,
  },
  button: {
    p: theme.spacing(2),
  },
  itemSpacing: {
    py: theme.spacing(1),
  },
  itemSpacingMedium: {
    py: theme.spacing(2),
  },
  itemSpacingLarge: {
    py: theme.spacing(3),
  },
});

const Message = ({ title, subtitle, buttonText, buttonTo }) => {
  const theme = useTheme();
  const classes = useClasses(theme);
  const navigate = useNavigate();

  console.log(title, subtitle, buttonText, buttonTo);

  const onSubmitClick = () => {
    navigate(buttonTo);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: theme.palette.main.background,
      }}
    >
      <Helmet>
        <title>Verification</title>
      </Helmet>
      <Paper sx={classes.paper}>
        <Box sx={classes.itemSpacingLarge}>
          <Typography sx={{ textAlign: "center" }} variant="h2">
            {title}
          </Typography>
        </Box>
        <Box sx={classes.itemSpacing}>
          <Typography sx={{ textAlign: "center" }} variant="body1">
            {subtitle}
          </Typography>
        </Box>

        {buttonText && (
          <Box sx={classes.itemSpacingMedium}>
            <Button
              sx={classes.button}
              variant="contained"
              color="secondary"
              fullWidth
              onClick={onSubmitClick}
            >
              {buttonText}
            </Button>
          </Box>
        )}
      </Paper>

      <Box sx={{ padding: theme.spacing(5) }} />
    </Box>
  );
};

const VerificationPage = () => {
  const { search } = useLocation();

  const token = new URLSearchParams(search).get("token");
  const auth = new Auth();

  const [status, setStatus] = useState({ loading: true });
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      navigate(SIGN_IN);
    }
    auth
      .doSendEmailVerification(token)
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          setStatus({
            loading: false,
            status: true,
            title: "Email Verification",
            subtitle:
              "Great! Turns out you are not a scammer. You may now proceed to log in.",
            buttonText: "Redirect",
            buttonTo: SIGN_IN,
          });
        } else {
          setStatus({
            loading: false,
            status: false,
            title: "Oops!",
            subtitle: res.message,
            buttonText: "Redirect",
            buttonTo: SIGN_IN,
          });
        }
      })
      .catch((error) => {
        setStatus({
          loading: false,
          status: false,
          title: "Oops!",
          subtitle: error.message,
          buttonText: "Redirect",
          buttonTo: SIGN_IN,
        });
      });
  }, []);

  // check if status object is empty
  if (status.loading) {
    return <LinearLoadingBar loading={status.loading} />;
  }

  return (
    <Box>
      <Message
        title={status.title}
        subtitle={status.subtitle}
        buttonText={status.buttonText}
        buttonTo={status.buttonTo}
      />
    </Box>
  );
};

VerificationPage.propTypes = {};

Message.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  buttonText: PropTypes.string,
  buttonTo: PropTypes.string,
};

export default VerificationPage;
