import { useTheme } from "@emotion/react";
import EmailIcon from "@mui/icons-material/Email";
import LockIcon from "@mui/icons-material/Lock";
import SearchIcon from "@mui/icons-material/Search";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import LabelIcon from "@mui/icons-material/Label";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import CampaignIcon from "@mui/icons-material/Campaign";
import ShortcutIcon from "@mui/icons-material/Shortcut";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import SettingsIcon from "@mui/icons-material/Settings";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import SchoolIcon from "@mui/icons-material/School";
import PropTypes from "prop-types";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import YouTubeIcon from "@mui/icons-material/YouTube";
import InstagramIcon from "@mui/icons-material/Instagram";
import LanguageIcon from "@mui/icons-material/Language";
import GavelIcon from "@mui/icons-material/Gavel";
import PublicIcon from "@mui/icons-material/Public";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";

const IconMapper = ({ name, color, size, gutter }) => {
  const theme = useTheme();
  const style = {
    color: color || theme.palette.main.darkAsh,
    width: size && size,
    height: size && size,
    marginRight: gutter && theme.spacing(gutter),
  };
  switch (name) {
    case "location":
      return <LocationOnIcon style={style} />;
    case "social":
      return <PublicIcon style={style} />;
    case "judge":
      return <GavelIcon style={style} />;
    case "twitter":
      return (
        <TwitterIcon
          style={{
            color: "#00acee",
          }}
        />
      );
    case "email":
      return (
        <EmailIcon
          style={{
            color: "#00acee",
          }}
        />
      );
    case "youtube":
      return (
        <YouTubeIcon
          style={{
            color: "#FF0000",
          }}
        />
      );
    case "instagram":
      return (
        <InstagramIcon
          style={{
            color: "#E1306C",
          }}
        />
      );
    case "web":
      return (
        <LanguageIcon
          style={{
            color: "#3f729b",
          }}
        />
      );

    case "institution":
      return <SchoolIcon style={style} />;
    case "facebook":
      return (
        <FacebookIcon
          style={{
            color: "#3b5998",
          }}
        />
      );
    case "github":
      return (
        <GitHubIcon
          style={{
            color: "#171515",
          }}
        />
      );
    case "linkedin":
      return (
        <LinkedInIcon
          style={{
            color: "#0e76a8",
          }}
        />
      );
    case "university":
      return <SchoolIcon sx={style} />;
    case "uploadFile":
      return <UploadFileIcon sx={style} />;
    case "fullScreen":
      return <FullscreenIcon sx={style} />;
    case "settings":
      return <SettingsIcon sx={style} />;
    case "visibilityOn":
      return <Visibility sx={style} />;
    case "visibilityOff":
      return <VisibilityOff sx={style} />;
    case "arrowDown":
      return <KeyboardArrowDownIcon sx={style} />;
    case "announcement":
      return <CampaignIcon sx={style} />;
    case "arrowUpward":
      return <ShortcutIcon sx={style} />;
    case "mail":
      return <EmailIcon sx={style} />;
    case "lock":
      return <LockIcon sx={style} />;
    case "search":
      return <SearchIcon sx={style} />;
    case "menu":
      return <MenuIcon sx={style} />;
    case "close":
      return <CloseIcon sx={style} />;
    case "label":
      return <LabelIcon sx={style} />;
    case "accountBalance":
      return <AccountBalanceIcon sx={style} />;
    default:
      return null;
  }
};

IconMapper.propTypes = {
  name: PropTypes.oneOf([
    "location",
    "social",
    "email",
    "judge",
    "twitter",
    "youtube",
    "instagram",
    "web",
    "institution",
    "facebook",
    "github",
    "linkedin",
    "university",
    "uploadFile",
    "mail",
    "fullScreen",
    "settings",
    "lock",
    "search",
    "visibilityOn",
    "visibilityOff",
    "menu",
    "close",
    "label",
    "accountBalance",
    "announcement",
    "arrowNext",
    "arrowUpward",
    "arrowDown",
  ]).isRequired,
  color: PropTypes.string,
  size: PropTypes.string,
  gutter: PropTypes.number,
};

export default IconMapper;
