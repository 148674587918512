import React from "react";
// import PropTypes from 'prop-types'
// mui
import { Box, Button, Paper, Typography, useTheme } from "@mui/material";
import TextInputLayout from "components/TextInputLayout";
import Adornment from "components/TextInputLayout/Adornment";
import { useLocation } from "react-router-dom";
import Auth from "api/auth";
import notify from "util/index";
import { Helmet } from "react-helmet-async";
import LinearLoadingBar from "components/LinearLoadingBar";

const useClasses = (theme) => ({
  paper: {
    maxWidth: 430,
    px: theme.spacing(7),
    pb: theme.spacing(5),
    mx: "auto",
    mt: theme.spacing(6),
    boxShadow: "0px 4px 13px rgba(61, 61, 61, 0.1)",
  },
  button: {
    p: theme.spacing(2),
  },
  itemSpacing: {
    py: theme.spacing(1),
  },
  itemSpacingMedium: {
    py: theme.spacing(2),
  },
  itemSpacingLarge: {
    py: theme.spacing(3),
  },
});

const ResetPasswordPage = () => {
  const theme = useTheme();
  const classes = useClasses(theme);
  const [form, setForm] = React.useState(null);
  const [showPassword, setShowPassword] = React.useState({
    pass: false,
    comp: false,
  });
  const [processing, setProcessing] = React.useState(false);
  const { search } = useLocation();
  const token = new URLSearchParams(search).get("token");
  const auth = new Auth();

  const onInput = (event) => {
    const { value, name } = event.target;
    setForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const onSubmitClick = () => {
    if (form.password !== form.confirmPassword) {
      notify("Passwords do not match", "info");
      return;
    }
    setProcessing(true);

    auth
      .doPasswordUpdate(token, form)
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          notify(res.message, "success");
        } else {
          notify(res.message, "info");
        }
        setProcessing(false);
      })
      .catch((error) => {
        console.log(error);
        notify(error, "error");
        setProcessing(false);
      });
  };

  const handleClickShowPassword = () => {
    setShowPassword({ ...showPassword, pass: !showPassword.pass });
  };
  const handleClickShowCompPassword = () => {
    setShowPassword({ ...showPassword, comp: !showPassword.comp });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div>
      <LinearLoadingBar loading={processing} />
      <Paper sx={classes.paper}>
        <Helmet>
          <title>Reset Password</title>
        </Helmet>
        <Box sx={classes.itemSpacingLarge}>
          <Typography sx={{ textAlign: "center" }} variant="h2">
            Reset Password
          </Typography>
        </Box>
        <Box sx={classes.itemSpacing}>
          <Typography sx={{ textAlign: "center" }} variant="subtitle1">
            You are one step away from resetting your password
          </Typography>
        </Box>
        <Box sx={classes.itemSpacing}>
          <TextInputLayout
            icon="lock"
            placeholder="Password"
            type={showPassword.pass ? "text" : "password"}
            name="password"
            endAdornment={
              <Adornment
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                visible={showPassword.pass}
              />
            }
            onInputChange={onInput}
          />
        </Box>
        <Box sx={classes.itemSpacing}>
          <TextInputLayout
            icon="lock"
            placeholder="Confirm Password"
            type={showPassword.comp ? "text" : "password"}
            endAdornment={
              <Adornment
                onClick={handleClickShowCompPassword}
                onMouseDown={handleMouseDownPassword}
                visible={showPassword.comp}
              />
            }
            name="confirmPassword"
            onInputChange={onInput}
          />
        </Box>

        <Box sx={classes.itemSpacingMedium}>
          <Button
            sx={classes.button}
            variant="contained"
            color="secondary"
            fullWidth
            onClick={onSubmitClick}
            disabled={processing}
          >
            Submit
          </Button>
        </Box>
      </Paper>
    </div>
  );
};

ResetPasswordPage.propTypes = {};

export default ResetPasswordPage;
