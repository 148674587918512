import React from "react";

// mui
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Paper,
  Typography,
  useTheme,
} from "@mui/material";

import TextInputLayout from "components/TextInputLayout";
import LinkedTypography from "components/LinkedTypography";
import Adornment from "components/TextInputLayout/Adornment";
import Auth from "api/auth";
import httpStatus from "http-status";
import { HOME } from "constants/route";
import { ACCESS_TOKEN } from "config/env";
import Cookies from "universal-cookie";
import notify from "util/index";
import { Helmet } from "react-helmet-async";
import LinearLoadingBar from "components/LinearLoadingBar";

const useClasses = (theme) => ({
  paper: {
    maxWidth: 430,
    px: theme.spacing(7),
    pb: theme.spacing(5),
    mx: "auto",
    mt: theme.spacing(6),
    boxShadow: "0px 4px 13px rgba(61, 61, 61, 0.1)",
  },
  button: {
    p: theme.spacing(2),
  },
  itemSpacing: {
    py: theme.spacing(1),
  },
  itemSpacingMedium: {
    py: theme.spacing(2),
  },
  itemSpacingLarge: {
    py: theme.spacing(3),
  },
});

const SignInPage = () => {
  const theme = useTheme();
  const classes = useClasses(theme);
  const [form, setForm] = React.useState(null);
  const [checked, setChecked] = React.useState(false);
  const [processing, setProcessing] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);

  const onInput = (event) => {
    const { value, name } = event.target;
    setForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const onSubmitClick = () => {
    form.isRememberMe = checked;
    setProcessing(true);

    const auth = new Auth();
    auth
      .signInWithEmail(form)
      .then((res) => {
        if (res.status == httpStatus.OK) {
          notify(res.message, "success");

          const cookies = new Cookies();
          cookies.set(ACCESS_TOKEN, res.token, res.config);
          // set delay
          setTimeout(() => {
            setProcessing(false);
            window.location.href = HOME;
          }, 1000);
        } else {
          console.log(res);
          setProcessing(false);
        }
      })
      .catch((error) => {
        console.log(error);
        notify(error, "error");
        setProcessing(false);
      });
  };

  const handleRememberMe = (event) => {
    setChecked(event.target.checked);
  };
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Box>
      <LinearLoadingBar loading={processing} />
      <Paper sx={classes.paper}>
        <Helmet>
          <title>Sign In</title>
        </Helmet>
        <Box sx={classes.itemSpacingLarge}>
          <Typography sx={{ textAlign: "center" }} variant="h2">
            Sign In
          </Typography>
        </Box>
        <Box sx={classes.itemSpacing}>
          <TextInputLayout
            icon="mail"
            placeholder="Email"
            type="email"
            name="email"
            onInputChange={onInput}
          />
        </Box>
        <Box sx={classes.itemSpacing}>
          <TextInputLayout
            icon="lock"
            endAdornment={
              <Adornment
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                visible={showPassword}
              />
            }
            placeholder="Password"
            type={showPassword ? "text" : "password"}
            name="password"
            onInputChange={onInput}
          />
        </Box>
        <LinkedTypography
          text="Forgot password?"
          to="/forgot-password"
          variant="subtitle2"
          color="secondary"
        />
        <Box sx={classes.itemSpacingMedium}>
          <Button
            sx={classes.button}
            variant="contained"
            color="secondary"
            fullWidth
            onClick={onSubmitClick}
            disabled={processing}
          >
            Submit
          </Button>
        </Box>
        <FormControlLabel
          control={
            <Checkbox
              size="small"
              checked={checked}
              onChange={handleRememberMe}
            />
          }
          label={<Typography variant="subtitle2">Remember me</Typography>}
        />
        {/* <ActionAlert title="asdas" message="sdasdsadasd" type="success" /> */}
      </Paper>
    </Box>
  );
};

SignInPage.propTypes = {};

export default SignInPage;
