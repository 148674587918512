/* eslint-disable no-unused-vars */
import React from "react";
import Cookies from "universal-cookie";
const { Navigate, useLocation } = require("react-router-dom");
import PropTypes from "prop-types";
import { HOME, SIGN_IN, SIGN_UP } from "constants/route";
import jwt_decode from "jwt-decode";
import { ACCESS_TOKEN } from "config/env";

const getCurrentUser = () => {
  const cookies = new Cookies();
  const token = cookies.get(ACCESS_TOKEN);
  if (!token) {
    return {
      isAuthenticated: false,
      profile: null,
    };
  }
  var decodedToken = jwt_decode(token);

  return {
    isAuthenticated: true,
    profile: {
      sub: decodedToken.sub,
      handle: decodedToken.handle,
      email: decodedToken.email,
      institution: decodedToken.institution,
      roles: decodedToken.roles,
    },
  };
};

function RequireAuth({ children }) {
  const location = useLocation();

  const user = getCurrentUser();
  console.log("USER", user);

  if (user.isAuthenticated) {
    console.log("auth cookie found!");
    return children;
  } else {
    console.log("auth cookie NOT found!");
    return (
      <Navigate to={SIGN_IN} replace state={{ path: location.pathname }} />
    );
  }
}

function NotRequireAuth({ children }) {
  const user = getCurrentUser();

  if (user.isAuthenticated) {
    window.location.href = HOME;
    return null;
  } else {
    return children;
  }
}

NotRequireAuth.propTypes = {
  children: PropTypes.node.isRequired,
};

RequireAuth.propTypes = {
  children: PropTypes.node.isRequired,
};

export { RequireAuth, NotRequireAuth, getCurrentUser };
