import { REACT_APP_CLIENT_ENDPOINT } from "config/env";
// auth routes
export const SIGN_UP = `/sign-up`;
export const SIGN_IN = "/sign-in";
export const NOT_FOUND = "/404";
export const UNAUTHORIZED = "/401";
export const FORGOT_PASSWORD = "/forgot-password";
export const RESET_PASSWORD = "/reset-password";
export const VERIFICATION = "/verify-email";

export const PRIVATE_PAGE = "/private"; // for test only. wont be used in production

// main routes
export const HOME = `${REACT_APP_CLIENT_ENDPOINT}`;
