// material-ui
import logo from "assets/logo.svg";
import PropTypes from "prop-types";

const Logo = ({ to }) => {
  return (
    <img
      src={logo}
      alt="AUSTOJ"
      width="130"
      onClick={() => {
        window.location.href = to;
      }}
      style={{
        cursor: "pointer",
      }}
    />
  );
};

Logo.propTypes = {
  to: PropTypes.string.isRequired,
};

export default Logo;
