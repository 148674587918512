import { useTheme } from "@emotion/react";
import { Box } from "@mui/material";
import Footer from "components/Footer";
import Navbar from "components/Navbar";
import { useState } from "react";
import { Outlet } from "react-router-dom";

const Layout = () => {
  // eslint-disable-next-line no-unused-vars
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        backgroundColor: theme.palette.main.background,
      }}
    >
      <Navbar onOpenSidebar={() => setOpen(true)} caller="auth" />
      <Outlet />
      <Box sx={{ mt: 5 }} />
      <Footer />
    </Box>
  );
};
export default Layout;
