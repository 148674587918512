import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { Box, Stack, Toolbar, IconButton, Button } from "@mui/material";
import { MHidden } from "components/@material-extend";
import MuiAppBar from "@mui/material/AppBar";
import React from "react";
import HamburgerMenu from "./Hamburger";
import { WINDOW_MARGIN } from "constants/dimens";
import { SIGN_IN, SIGN_UP } from "constants/route";
import IconMapper from "components/IconMapper";
import { useNavigate } from "react-router-dom";
import Logo from "components/LogoSection";
import { REACT_APP_CLIENT_ENDPOINT } from "config/env";

const ToolbarStyle = styled(Toolbar)(() => ({
  paddingLeft: 0,
}));

const AppBarStyle = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme }) => ({
  boxShadow: "none",
  backgroundColor: theme.palette.main.white,
}));

// ----------------------------------------------------------------------

const HEADERS = {
  button: {
    signin: {
      text: "Sign In",
      to: SIGN_IN,
    },
    signup: {
      text: "Sign Up",
      to: SIGN_UP,
    },
  },
};

export default function Navbar({ gutter, padding }) {
  const [isMenuOpen, setMenuOpen] = React.useState(false);
  const navigate = useNavigate();

  const onHamburgerClick = (event) => {
    setMenuOpen(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setMenuOpen(null);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <AppBarStyle position="relative">
        <ToolbarStyle
          disableGutters
          sx={{
            mx: gutter,
            py: padding || 1.5,
            margin: gutter ? "" : WINDOW_MARGIN,
          }}
        >
          <Logo to={REACT_APP_CLIENT_ENDPOINT} />

          {/* normal view */}
          <MHidden width={"smDown"}>
            {/* auth buttons */}
            <Stack
              direction="row"
              alignItems="center"
              spacing={{ xs: 0.5, sm: 1.5 }}
              sx={{ marginLeft: "auto" }}
            >
              <Button
                size="large"
                variant="primary"
                color="dark"
                onClick={() => navigate(HEADERS.button.signin.to)}
              >
                {HEADERS.button.signin.text}
              </Button>

              <Button
                size="large"
                variant="primary"
                color="light"
                onClick={() => navigate(HEADERS.button.signup.to)}
              >
                {HEADERS.button.signup.text}
              </Button>
            </Stack>
          </MHidden>
          {/* hamburger icon when device in mobile view */}
          <MHidden width={"smUp"}>
            <IconButton
              sx={{ ml: "auto" }}
              size="large"
              onClick={onHamburgerClick}
            >
              <IconMapper name="menu" />
            </IconButton>
          </MHidden>

          <HamburgerMenu
            setMenuOpen={setMenuOpen}
            isMenuOpen={isMenuOpen}
            handleCloseNavMenu={handleCloseNavMenu}
            HEADERS={HEADERS}
          />
        </ToolbarStyle>
      </AppBarStyle>
    </Box>
  );
}

HamburgerMenu.propTypes = {
  setMenuOpen: PropTypes.func,
};

Navbar.propTypes = {
  onOpenSidebar: PropTypes.func.isRequired,
  gutter: PropTypes.number, // OPTIONAL: called from problemPage
  padding: PropTypes.number, // OPTIONAL: called from problemPage
};
