import React from "react";
import PropTypes from "prop-types";
// import { makeStyles } from "@mui/styles";
import { Paper, InputBase } from "@mui/material";
import { useState } from "react";
import { useTheme } from "@emotion/react";
import IconMapper from "components/IconMapper";

/**
 * A component that returns the Filled Text Input component
 * @param icon bookmark, mail, location, requirements, phone, delete, upload, user, lock, key, company, description
 * @param placeholder A String for the hint text
 * @param type email, file, date, image, text
 * @param readOnly true or false
 * @param rowsMax integer value (optional)
 * @param value String: default value on the input layout
 * @param name String: name of the input, ie email or password
 * @param onInputChange a function passed that handles the input change event
 */

const TextInputLayout = (props) => {
  //   const classes = useStyles();
  const [value] = useState(props.value ? props.value : "");
  const theme = useTheme();

  return (
    <Paper
      component="form"
      sx={{
        padding: "2px 20px",
        display: "flex",
        alignItems: "center",
        bgcolor: theme.palette.main.lightAsh2,
        borderRadius: 1,
      }}
      elevation={0}
    >
      <IconMapper name={props.icon} color={theme.palette.main.darkAsh} />
      <InputBase
        name={props.name}
        sx={{
          marginLeft: "10px",
          flex: 1,
          color: theme.palette.main.black,
          padding: "10px",
        }}
        endAdornment={props.endAdornment}
        placeholder={props.placeholder}
        fullWidth={true}
        onChange={props.onInputChange}
        type={props.type}
        defaultValue={value}
        maxRows={10}
        inputProps={{
          style: {
            // ! cant you use theme.customShadows.primary here? @matcovic
            WebkitBoxShadow: "0 0 0 1000px var(--color-lightAsh2) inset",
          },
        }}
      />
    </Paper>
  );
};

TextInputLayout.propTypes = {
  icon: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  value: PropTypes.string,
  name: PropTypes.string.isRequired,
  endAdornment: PropTypes.element,
  onInputChange: PropTypes.func.isRequired,
};

export default TextInputLayout;
