import PropTypes from "prop-types";
import { Button, Menu, MenuItem } from "@mui/material";
import React from "react";
// icons
import { useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";

const HamburgerMenu = ({ isMenuOpen, handleCloseNavMenu, HEADERS }) => {
  const navigate = useNavigate();
  const theme = useTheme();

  return (
    <Menu
      id="menu-appbar"
      anchorEl={isMenuOpen}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(isMenuOpen)}
      onClose={handleCloseNavMenu}
      sx={{
        display: { xs: "block" },
      }}
    >
      <MenuItem
        sx={{ justifyContent: "center", padding: 0 }}
        onClick={handleCloseNavMenu}
      >
        <Button
          fullWidth
          size="medium"
          variant="primary"
          color="dark"
          onClick={() => navigate(HEADERS.button.signin.to)}
          sx={{
            color: theme.palette.main.white,
            px: theme.spacing(6),
            py: theme.spacing(2),
            borderRadius: 0,
          }}
        >
          {HEADERS.button.signin.text}
        </Button>
      </MenuItem>

      <MenuItem
        sx={{ justifyContent: "center", padding: 0 }}
        onClick={handleCloseNavMenu}
        disableRipple
      >
        <Button
          fullWidth
          size="medium"
          variant="primary"
          color="light"
          onClick={() => navigate(HEADERS.button.signup.to)}
          sx={{ px: theme.spacing(6), borderRadius: 0 }}
        >
          {HEADERS.button.signup.text}
        </Button>
      </MenuItem>
    </Menu>
  );
};

HamburgerMenu.propTypes = {
  isMenuOpen: PropTypes.func.isRequired,
  HEADERS: PropTypes.object.isRequired,
  handleCloseNavMenu: PropTypes.func.isRequired,
};

export default HamburgerMenu;
