import { toast } from "react-toastify";

/**
 *
 * @param {*} text
 * @param {*} type [dark, success, warning, error, info]
 * @returns
 */
const notify = (text, type) =>
  toast[type](text, {
    position: "top-center",
    autoClose: 3000,
    pauseOnHover: true,
  });

export default notify;
