import {
  parseError,
  parseResponse,
  parseResponseSignin,
} from "util/payloadHandler";
import Api from "./api";
import Cookies from "universal-cookie";
import { ACCESS_TOKEN } from "config/env";

export default class Auth extends Api {
  constructor() {
    super();
    this.init("/api/auth/v1");
  }

  /**
   * Creates a new user with email and password
   * @param {String} email
   * @param {String} password
   * @param {String} handle
   * @param {String} institution
   * @returns a response object from the server
   */
  async createUserWithEmail(form) {
    try {
      console.log("hallee");
      const res = await this.client.post(`/sign-up`, form);
      return parseResponse(res);
    } catch (error) {
      return parseError(error);
    }
  }

  /**
   * Signs in the user with email and password
   * @param {String} email
   * @param {String} password
   * @param {Boolean} isRememberMe
   * @returns a response object from the server
   */
  async signInWithEmail(form) {
    try {
      const res = await this.client.post(`/sign-in`, form);
      return parseResponseSignin(res);
    } catch (error) {
      return parseError(error);
    }
  }

  /**
   * Sign out the user
   */
  async doSignOut() {
    try {
      const res = await this.client.post(`/sign-out`);
      // clear a cookie
      Cookies.remove(ACCESS_TOKEN);

      return parseResponse(res);
    } catch (error) {
      return parseError(error);
    }
  }

  // password reset
  async doPasswordResetRequest(email) {
    try {
      const res = await this.client.post(`/forgot-password`, { email: email });
      return parseResponse(res);
    } catch (error) {
      return parseError(error);
    }
  }

  // password update
  async doPasswordUpdate(token, form) {
    try {
      const res = await this.client.post(
        `/reset-password?token=${token}`,
        form
      );
      return parseResponse(res);
    } catch (error) {
      return parseError(error);
    }
  }

  // send email verification
  async doSendEmailVerification(token) {
    try {
      const res = await this.client.get(`/verify-email?token=${token}`);
      return parseResponse(res);
    } catch (error) {
      return parseError(error);
    }
  }
}
