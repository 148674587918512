function createGradient(color1, color2) {
  return `linear-gradient(to bottom, ${color1}, ${color2})`;
}

const colors = {};

colors.GREY = {
  0: "#FFFFFF",
  100: "#F9FAFB",
  200: "#F4F6F8",
  300: "#DFE3E8",
  400: "#C4CDD5",
  500: "#919EAB",
  600: "#637381",
  700: "#454F5B",
  800: "#202020",
  900: "#484848",
};

colors.INFO = {
  main: "#1890FF",
};

colors.SUCCESS = {
  main: "#54D62C",
};

colors.WARNING = {
  main: "#FFC107",
};

colors.ERROR = {
  main: "#FF4842",
};

colors.GRADIENTS = {
  // primary: createGradient(colors.PRIMARY.light, colors.PRIMARY.main),
  info: createGradient(colors.INFO.light, colors.INFO.main),
  success: createGradient(colors.SUCCESS.light, colors.SUCCESS.main),
  warning: createGradient(colors.WARNING.light, colors.WARNING.main),
  error: createGradient(colors.ERROR.light, colors.ERROR.main),
};

// use this for the default theme
const PRIMARY = {
  main: "#202020",
};

const SECONDARY = {
  main: "#484848",
};

// use this for the default theme
const MAIN = {
  lightPurple: "#BCB2FF",
  navyBlue: "#8290A0",
  black: "#202020",
  blackShade: "#484848",
  textSecondary: "#484848",
  darkAsh: "#969696",
  white: "#fff",
  background: "#F5F5F5",
  lightAsh: "#E8E9EB",
  lightAsh2: "#F4F4F4",
  green: "#04C900",
  red: "#D67979",
  softYellow: "#FFF7CD",
  softBlue: "#D0F2FF",
  softGreen: "#C8FACD",
  darkRed: "#7A0C2E",
};

// light palette
const palette = {
  common: { black: MAIN.blackShade, white: MAIN.white },
  primary: { ...PRIMARY },
  secondary: { ...SECONDARY },
  main: { ...MAIN },
  info: { ...colors.INFO },
  success: { ...colors.SUCCESS },
  warning: { ...colors.WARNING },
  error: { ...colors.ERROR },
  grey: colors.GREY,
  gradients: colors.GRADIENTS,
  divider: MAIN.background,
  text: {
    primary: MAIN.black,
    secondary: MAIN.textSecondary,
    disabled: MAIN.darkAsh,
  },
  background: {
    paper: MAIN.white,
    default: MAIN.white,
    neutral: MAIN.lightAsh2,
  },
  action: {
    active: colors.GREY[600],
    hover: MAIN.lightAsh,
    selected: colors.GREY[500],
    disabled: colors.GREY[500],
    disabledBackground: colors.GREY[500],
    focus: colors.GREY[300],
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
};

export default palette;
