// ----------------------------------------------------------------------

export default function Button(theme) {
  return {
    MuiButton: {
      variants: [
        {
          props: { variant: "primary", color: "dark" },
          style: {
            boxShadow: theme.customShadows.primary,
            borderRadius: theme.spacing(1),
            backgroundColor: theme.palette.main.blackShade,
            "&:hover": {
              backgroundColor: theme.palette.main.black,
              transition: "background-color 0.2s ease-in-out",
            },
          },
        },
        {
          props: { variant: "primary", color: "light" },
          style: {
            boxShadow: theme.customShadows.primary,
            borderRadius: theme.spacing(1),
            backgroundColor: theme.palette.main.lightAsh2,
            color: theme.palette.main.black,
            "&:hover": {
              backgroundColor: theme.palette.main.lightAsh,
              transition: "background-color 0.2s ease-in-out",
            },
          },
        },
      ],
      styleOverrides: {
        root: {
          "&:hover": {
            boxShadow: "none",
          },
          // add disabled styles
          "&:disabled": {
            backgroundColor: theme.palette.main.lightAsh,
            color: theme.palette.main.black,
          },
        },
        sizeLarge: {
          height: 48,
        },
        containedInherit: {
          color: theme.palette.grey[800],
          boxShadow: theme.customShadows.z8,
          "&:hover": {
            backgroundColor: theme.palette.grey[400],
          },
        },
        containedPrimary: {
          boxShadow: theme.customShadows.primary,
        },
        containedSecondary: {
          boxShadow: theme.customShadows.secondary,
        },
        outlinedInherit: {
          border: `1px solid ${theme.palette.grey[500]}`,
          "&:hover": {
            backgroundColor: theme.palette.action.hover,
          },
        },
        textInherit: {
          "&:hover": {
            backgroundColor: theme.palette.action.hover,
          },
        },
      },
    },
  };
}
