import React from "react";
// mui
import { Box, Button, Paper, Typography, useTheme } from "@mui/material";
import TextInputLayout from "components/TextInputLayout";
import Auth from "api/auth";
import httpStatus from "http-status";
import notify from "util/index";
import { Helmet } from "react-helmet-async";
import LinearLoadingBar from "components/LinearLoadingBar";

const useClasses = (theme) => ({
  paper: {
    maxWidth: 440,
    px: theme.spacing(7),
    pb: theme.spacing(5),
    mx: "auto",
    mt: theme.spacing(6),
    boxShadow: "0px 4px 13px rgba(61, 61, 61, 0.1)",
  },
  button: {
    p: theme.spacing(2),
  },
  itemSpacing: {
    py: theme.spacing(1),
  },
  itemSpacingMedium: {
    py: theme.spacing(2),
  },
  itemSpacingLarge: {
    py: theme.spacing(3),
  },
});

const ForgotPasswordPage = () => {
  const theme = useTheme();
  const classes = useClasses(theme);
  const [form, setForm] = React.useState(null);
  const [processing, setProcessing] = React.useState(false);

  console.log(open);

  const onInput = (event) => {
    const { value, name } = event.target;
    setForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const onSubmitClick = () => {
    const { email } = form;
    const auth = new Auth();
    setProcessing(true);
    auth
      .doPasswordResetRequest(email)
      .then((res) => {
        console.log(res);
        if (res.status == httpStatus.OK) {
          console.log("success", res.message);
          notify(res.message, "success");
          setProcessing(false);
        }
      })
      .catch((err) => {
        console.log(err);
        notify(err, "error");
        setProcessing(false);
      });
  };

  return (
    <div>
      <LinearLoadingBar loading={processing} />
      <Paper sx={classes.paper}>
        <Helmet>
          <title>Forgot Password</title>
        </Helmet>
        <Box sx={classes.itemSpacingLarge}>
          <Typography sx={{ textAlign: "center" }} variant="h2">
            Forgot Password
          </Typography>
        </Box>
        <Box sx={classes.itemSpacing}>
          <Typography sx={{ textAlign: "center" }} variant="subtitle1">
            Forgot your password? Don’t worry just enter your email
          </Typography>
        </Box>
        <Box sx={classes.itemSpacing}>
          <TextInputLayout
            icon="mail"
            placeholder="Email"
            type="email"
            name="email"
            onInputChange={onInput}
          />
        </Box>

        <Box sx={classes.itemSpacingMedium}>
          <Button
            sx={classes.button}
            variant="contained"
            color="secondary"
            fullWidth
            onClick={onSubmitClick}
            disabled={processing}
          >
            Submit
          </Button>
        </Box>
      </Paper>
    </div>
  );
};

ForgotPasswordPage.propTypes = {};

export default ForgotPasswordPage;
