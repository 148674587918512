const { LinearProgress } = require("@mui/material");
import PropTypes from "prop-types";

const LinearLoadingBar = ({ loading }) => {
  return loading && <LinearProgress color="info" />;
};

LinearLoadingBar.propTypes = {
  loading: PropTypes.bool,
};

export default LinearLoadingBar;
